<template>
  <div class="custom-container">
    <div class="table-container">
      <div class="title">月度销售数据分析</div>
      <div class="line-container">
        <EchartsLine :echartsData="list"></EchartsLine>
      </div>
      <vxe-table :data="list">
        <vxe-table-column title="日历月" field="name" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="实际-销售额" field="actualSales" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="实际-销量-吨" field="actualSold" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="实际-成本" field="actualCost" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="实际-费用" field="actualFee" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="去年-销售额" field="lastYearSales" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="环比增长" field="grow" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="计划达成度" field="reachDegree" min-width="100">
          <template v-slot="{row}">
            <el-progress :text-inside="true" :stroke-width="14" :percentage="row.reachDegree"></el-progress>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
  </div>
</template>
<script>
import EchartsLine from '../components/echarts_line.vue';

export default {
  components: {
    EchartsLine,
  },
  data() {
    return {
      list: [
        {
          name: '1月', actualSales: 19892632, actualSold: 3978526.4, actualCost: 9946316, actualFee: 5967789.6, lastYearSales: 18380791.97, grow: '7.6%', reachDegree: 98,
        },
        {
          name: '2月', actualSales: 11867632, actualSold: 2373526.4, actualCost: 5933816, actualFee: 3560289.6, lastYearSales: 11499735.41, grow: '3.1%', reachDegree: 97,
        },
        {
          name: '3月', actualSales: 16892234, actualSold: 3378446.8, actualCost: 8446117, actualFee: 5067670.2, lastYearSales: 16419251.45, grow: '2.8%', reachDegree: 90,
        },
        {
          name: '4月', actualSales: 9856432, actualSold: 1971286.4, actualCost: 4928216, actualFee: 2956929.6, lastYearSales: 9324184.672, grow: '5.4%', reachDegree: 98,
        },
        {
          name: '5月', actualSales: 8867841, actualSold: 1773568.2, actualCost: 4433920.5, actualFee: 2660352.3, lastYearSales: 8965387.251, grow: '-1.1%', reachDegree: 96,
        },
        {
          name: '6月', actualSales: 8696723, actualSold: 1739344.6, actualCost: 4348361.5, actualFee: 2609016.9, lastYearSales: 8331460.634, grow: '4.2%', reachDegree: 87,
        },
        {
          name: '7月', actualSales: 19892632, actualSold: 3978526.4, actualCost: 9946316, actualFee: 5967789.6, lastYearSales: 18380791.97, grow: '7.6%', reachDegree: 98,
        },
        {
          name: '8月', actualSales: 11867632, actualSold: 2373526.4, actualCost: 5933816, actualFee: 3560289.6, lastYearSales: 11499735.41, grow: '3.1%', reachDegree: 97,
        },
        {
          name: '9月', actualSales: 16892234, actualSold: 3378446.8, actualCost: 8446117, actualFee: 5067670.2, lastYearSales: 16419251.45, grow: '2.8%', reachDegree: 90,
        },
        {
          name: '10月', actualSales: 9856432, actualSold: 1971286.4, actualCost: 4928216, actualFee: 2956929.6, lastYearSales: 9324184.672, grow: '5.4%', reachDegree: 98,
        },
        {
          name: '11月', actualSales: 8867841, actualSold: 1773568.2, actualCost: 4433920.5, actualFee: 2660352.3, lastYearSales: 8965387.251, grow: '-1.1%', reachDegree: 96,
        },
        {
          name: '12月', actualSales: 8696723, actualSold: 1739344.6, actualCost: 4348361.5, actualFee: 2609016.9, lastYearSales: 8331460.634, grow: '4.2%', reachDegree: 87,
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    color: transparent;
  }
  .custom-container{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  .title{
    font-size: 20px;
    text-align: center;
    color: #333333;
    font-weight: bold;
    padding: 10px 0;
  }
  .line-container{
    width: 100%;
    height: 250px;
  }
</style>
