<template>
  <div id="line" ref="line"></div>
</template>
<script>
import echarts from 'echarts';

export default {
  name: 'echarts_line',
  props: {
    echartsData: Array,
  },
  data() {
    return {
      // 图表配置信息
      options: {},
    };
  },
  methods: {
    getOptions() {
      const options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          // formatter(vals) {
          //   const val = vals[0];
          //   const str = `${val.name}<br />${val.seriesName}：${val.value ? val.value.actualSales : '-'}元`;
          //   return str;
          // },
        },
        grid: {
          top: 30,
          left: 30,
          right: 40,
          bottom: 30,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          name: '元',
        },
        dataset: {
          dimensions: ['name', 'actualSales'],
          source: this.echartsData,
        },
        series: [
          { name: '销售额', type: 'line', smooth: true },
        ],
      };
      return options;
    },
    // 初始化echarts
    initEchart() {
      const chartDiv = this.$refs.line;
      const myChart = echarts.init(chartDiv);
      myChart.setOption(this.getOptions());
    },
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.initEchart();
    });
  },
};
</script>
<style lang="less" scoped>
#line{
  width: 100%;
  height: 100%;
}
</style>
